import utilsService from '@/app/helpers/utilsService';
import fetchWrapper from '../fetchWrapper';
import {
    DataPaymentRequest,
    MethodRequest,
    PaymentMethodDesktopResponse,
    PaymentMethodMobileResponse,
    PaymentRequest,
    PaymentRequestMobile,
    PaymentResponseAfterCallBack,
    PaymentResponseAfterCallForMobileResponse,
    PaymentResponseAfterCallResponse,
    PaymentSubmitWorkResponse,
    PaymentVerificationRequest,
} from '@/app/models/payments';
import { PaginatedSubmissionPaymentResponse } from '@/app/models/submission';

const BASE_URL = process.env.BASE_URL;

interface ISubmissionNeedToPayRequest {
    status: number;
    challengeId: number | string;
    submissionId: number | string;
}

async function checkPaid(challengeId: number | string): Promise<PaymentSubmitWorkResponse> {
    const basePath = `challenges/${challengeId}/paid`;
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/${basePath}`);
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function getSubmissionNeedToPay({ status, challengeId, submissionId }: ISubmissionNeedToPayRequest): Promise<PaginatedSubmissionPaymentResponse> {
    const basePath = `submissions/pay?status=${status}&challenge_id=${challengeId}&submission_id=${submissionId}`;
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/${basePath}`);
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function getMethod({ platform }: MethodRequest): Promise<PaymentMethodMobileResponse | PaymentMethodDesktopResponse> {
    const basePath = `payments/methods?platform=${platform}`;
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/${basePath}`);
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function postActionPaymentSubmission({ platform, challengeId, method, submissionId }: DataPaymentRequest): Promise<PaymentResponseAfterCallResponse | PaymentResponseAfterCallForMobileResponse> {
    const basePath = `payments/submission`;
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${basePath}`, await utilsService.appendToFormData({ platform, challengeId, method, submissionId }));
        const camelizedResponse = utilsService.toCamelCase(response?.data, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

function parseFormData(textData: string): { [key: string]: string } {
    const params = new URLSearchParams(textData);
    const data: { [key: string]: string } = {};
    params.forEach((value, key) => {
        data[key] = value;
    });
    return data;
}

async function postVerification({ data }: PaymentVerificationRequest): Promise<PaymentResponseAfterCallBack> {
    const basePath = `payments/callback`;
    try {
        const response = await fetch(`${BASE_URL}/${basePath}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();

        const camelizedResponse = utilsService.toCamelCase(responseData, { deep: true });

        return camelizedResponse;
    } catch (error) {
        console.error('Error in postVerification:', error);
        throw error;
    }
}
// return `${process.env.BASE_URL}/payments/callback`;

export const paymentService = {
    checkPaid,
    getSubmissionNeedToPay,
    getMethod,
    postActionPaymentSubmission,
    postVerification,
};
