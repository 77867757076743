'use client';
import React, { useEffect, useState } from 'react';
import { AttendanceCheck, EllipseRed, MissionParticipation, RouletteIcon, SubmitYourWork, WayToCome } from '@/public/icon';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useRouter } from 'next/navigation';
import BaseModal from '@/app/base-ui/BaseModalUI';
import PermissionWrapper from '@/app/components/Auth/PermissionWrapper';
import { useGlobalDialog } from '@/app/contexts/global-dialog';
import { useUser } from '@/app/hooks/useUser';
import { useGlobalDialogV2 } from '@/app/contexts/GlobalDialogV2';
import { challengeService } from '@/app/service/challengeService/challengeService';
import utilsService from '@/app/helpers/utilsService';
import { paymentService } from '@/app/service/payments';
import BaseCardNoticeGray from '@/app/base-ui/BaseCardNoticeGray';
import { PaymentRequestSubmitWork } from '@/app/models/payments';

const isQuickLinkEnabled = process.env.NEXT_PUBLIC_SUBMISSION_QUICK_LINK_ENABLED === 'true';
const ChallengeID = process.env.NEXT_PUBLIC_SUBMISSION_CHALLENGE_ID;
const STEPS: any = { 1: 'one', 2: 'two', 3: 'three' };
const items = [
    { type: 'attendance', icon: <AttendanceCheck />, label: '출석체크', hasNotifications: false },
    { type: 'roulette', icon: <RouletteIcon />, label: '룰렛', hasNotifications: false },
    { type: 'mission_participation', icon: <MissionParticipation />, label: '미션참여', hasNotifications: false },
    { type: 'submit-work', icon: <SubmitYourWork />, label: '작품투고', hasNotifications: process.env.NEXT_PUBLIC_SUBMISSION_QUICK_LINK_ENABLED },
    { type: 'contact', icon: <WayToCome />, label: '오시는길', hasNotifications: false },
    // { icon: <QualificationTest />, label: '자격시험' },
    // { icon: <WayToCome />, label: '오시는길' },
];
const ListActions = () => {
    const router = useRouter();
    const [openModal, setOpenModal] = useState(false);
    const { checkPermissions } = useUser();
    const [modalContent, setModalContent] = useState({ title: '', description: '' });
    const { loadingDialog } = useGlobalDialog();
    const { showDialog, hideDialog } = useGlobalDialogV2();

    const redirectTo = async (type: string, hasNotifications: boolean) => {
        loadingDialog.open();
        if (['roulette'].includes(type)) {
            loadingDialog.close();
            return router.push(`/events/${type}/latest`, { scroll: false });
        }
        if (!['submit-work', 'contact', 'mission_participation', 'roulette'].includes(type)) {
            const checkPermission = await checkPermissions();
            if (checkPermission) {
                loadingDialog.close();
                return router.push(`/events/${type}/latest`, { scroll: false });
            } else {
                return router.push('/login');
            }
        }
        if (['mission_participation'].includes(type)) {
            loadingDialog.close();

            return router.push(`/community/challenges/?filtered=participable`);
        }
        if (['contact'].includes(type)) {
            loadingDialog.close();

            return router.push(`/directions`, { scroll: false });
        }
        if (type === 'submit-work') {
            if (hasNotifications) {
                if (ChallengeID) handleLogicRedirect();
                else if (!ChallengeID) {
                    showAlert('새 신청서를 제출하기 전에 비용을 지불해야 합니다.');
                    loadingDialog.close();
                }
                return;
            }
            showAlert();
            loadingDialog.close();
            return;
        }
        setModalContent({
            title: '이 기능은 열심히 개발중입니다.<br />멋진 업데이트로 찾아뵙겠습니다!',
            description: '',
        });
        setOpenModal(true);
        loadingDialog.close();
        return;
    };

    const handleLogicRedirect = async () => {
        await handleCheckChallenge();
    };

    const fetchChallengeDetail = async (): Promise<{ isParticipated: boolean; isPaid: boolean } | undefined> => {
        try {
            if (!ChallengeID) return;
            const { data } = await challengeService.getChallengeDetails({ challengeId: ChallengeID });
            return { isParticipated: data?.is_participated, isPaid: data?.is_paid_participation };
        } catch (error) {}
    };

    const handleCheckChallenge = async () => {
        try {
            const isChecking = await fetchChallengeDetail();
            if (isChecking?.isParticipated && isChecking?.isPaid) {
                const checkIsPaid = await checkPaid();
                if (checkIsPaid && !checkIsPaid?.isPaidAlready) {
                    handleShowDialogWarning(checkIsPaid?.fee as any, checkIsPaid?.submissionId as any);
                    loadingDialog.close();
                    return;
                }
            }
            await handleLogicContinueSubmit(ChallengeID);
        } catch (e) {}
    };

    const handleLogicContinueSubmit = async (challengeId: any) => {
        try {
            const response = await challengeService.getParticipantEd({ challengeId });
            const data = response.data.submission;
            if (!data) {
                router.push(`/community/challenges/${challengeId}/submitworks/one`);
                return;
            }
            const stepToContinue = utilsService.getLastStep(data?.step);
            if (stepToContinue && stepToContinue?.toString() === '4') {
                router.push(`/community/challenges/${challengeId}/submitworks/three`);
            } else {
                if (stepToContinue && stepToContinue?.toString()) {
                    router.push(`/community/challenges/${challengeId}/submitworks/${STEPS[stepToContinue]}`);
                } else {
                    showAlert();
                    loadingDialog.close();
                }
            }
        } catch (error) {
            const message = utilsService.getErrorMessage(error);
            showAlert(message);
            loadingDialog.close();
        }
    };

    const handleAcceptForPaidFeeType = async (submissionId: number) => {
        loadingDialog.open();
        hideDialog?.();
        router.replace(`/payments/submission?id=${submissionId}&challengeId=${ChallengeID}`);
    };

    const handleShowDialogWarning = (fee: number, submissionId: number) => {
        const message = `<span style='font-weight: 500'>출품이 완료되지 않았습니다.</span>`;
        showDialog({
            title: message,
            message: '',
            acceptText: '네, 납부할게요!',
            cancelText: '아니오',
            showBaseCardNotice: true,
            showAccept: true,
            showCancel: true,
            baseCardContent: renderBaseCard(`
             <span style='font-weight: 500; line-height: 100%; white-space: pre-line'>본 챌린지는 참가비  <span style='font-size: 12px;color: #FF2164;'>${utilsService.formatNumber(
                 fee ?? 0
             )}원</span>을 납부해야<br/><span>출품이 완료됩니다.

             참가하시겠습니까?</span>
             </span>`),
            onAccept: () => handleAcceptForPaidFeeType(submissionId),
            onCancel: hideDialog,
        });
    };

    const renderBaseCard = (content: string) => {
        return (
            <BaseCardNoticeGray
                content={content}
                style={{ height: '63px', flexShrink: 0 }}
                sx={{ lineHeight: '100%' }}
            />
        );
    };

    const checkPaid = async (): Promise<{ isPaidAlready: boolean; fee: number; submissionId: number } | undefined> => {
        try {
            if (!ChallengeID) return;
            const { data } = await paymentService.checkPaid(ChallengeID as any);
            if (data !== null) {
                return { isPaidAlready: data?.isPaid, fee: data?.price, submissionId: data?.submissionId };
            }
            return undefined;
        } catch (error) {
            // return undefined;
        }
    };
    // /payments/submission?id=192&challengeId=121
    const showAlert = (message?: string) => {
        showDialog({
            message: '',
            title: message ?? '진행중인 작품투고 챌린지가 없습니다.',
            acceptText: '확인',
            onAccept: hideDialog,
            showAccept: true,
            showCancel: false,
        });
    };

    return (
        <>
            <Box sx={{ paddingTop: '18px', paddingBottom: '18px', paddingRight: '20px', paddingLeft: '20px', gap: 15, display: { tablet: 'flex', laptop: 'flex', mobile: 'none' } }}>
                {items.map((item, index) => {
                    if (['attendance', 'roulette', 'submit-work'].includes(item.type)) {
                        return (
                            <PermissionWrapper key={index}>
                                <Box
                                    key={index}
                                    sx={{ display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'center', gap: 2.5, cursor: 'pointer' }}
                                    component="div"
                                    onClick={() => redirectTo(item.type, isQuickLinkEnabled)}
                                >
                                    <ButtonBase
                                        // onClick={() => redirectTo(item.type, isQuickLinkEnabled)}
                                        component="div"
                                        sx={{ borderRadius: 1, padding: 2, margin: -2 }}
                                    >
                                        {item.icon}
                                    </ButtonBase>

                                    {item.hasNotifications ? (
                                        <Box sx={{ position: 'absolute', bottom: 5, right: 0 }}>
                                            <EllipseRed />
                                        </Box>
                                    ) : null}

                                    <Typography
                                        variant="h2_10Medium"
                                        lineHeight="100%"
                                    >
                                        {item.label}
                                    </Typography>
                                </Box>
                            </PermissionWrapper>
                        );
                    }
                    return (
                        <Box
                            key={index}
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2.5, cursor: 'pointer' }}
                            component="div"
                            onClick={() => redirectTo(item.type, isQuickLinkEnabled)}
                        >
                            <ButtonBase
                                // onClick={() => redirectTo(item.type, isQuickLinkEnabled)}
                                component="div"
                                sx={{ borderRadius: 1, padding: 2, margin: -2 }}
                            >
                                {item.icon}
                            </ButtonBase>

                            <Typography
                                variant="h2_10Medium"
                                lineHeight="100%"
                            >
                                {item.label}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
            <Box
                sx={{
                    paddingTop: '18px',
                    paddingBottom: '18px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    gap: 5,
                    display: { tablet: 'none', laptop: 'none', mobile: 'flex' },
                    justifyContent: 'center',
                }}
            >
                {items.map((item, index) => (
                    <Box
                        key={index}
                        sx={{ display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'center', gap: 2.5 }}
                        onClick={() => redirectTo(item.type, isQuickLinkEnabled)}
                    >
                        <ButtonBase
                            component="div"
                            sx={{ borderRadius: 1, padding: 2, margin: -2 }}
                        >
                            {item.icon}
                        </ButtonBase>
                        {item.hasNotifications ? (
                            <Box sx={{ position: 'absolute', bottom: 5, right: 0 }}>
                                <EllipseRed />
                            </Box>
                        ) : null}
                        <Typography
                            variant="h2_10Medium"
                            lineHeight="100%"
                        >
                            {item.label}
                        </Typography>
                    </Box>
                ))}
            </Box>
            {openModal && (
                <BaseModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title={modalContent.title}
                    description={modalContent.description}
                    showAccept={true}
                    showCancel={false}
                    onCancel={() => setOpenModal(false)}
                    onAccept={() => setOpenModal(false)}
                    acceptText="확인"
                />
            )}
        </>
    );
};

export default ListActions;
